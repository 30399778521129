<div class="w-full py-6 md:py-12" *ngIf="loaded$ | async">
	<div class="w-full max-w-4xl mx-auto">
		<div class="flex items-center justify-between mb-4">
			<h2 class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
				My current plan
			</h2>
			<a [routerLink]="'edit'" class="font-sans text-sm font-semibold text-brand">Manage plans</a>
		</div>
		<div class="group w-full bg-white dark:bg-muted-800 border border-muted-200
				dark:border-muted-700 p-8 py-12 rounded-lg">
			<div class="flex items-center justify-between mb-4" *ngIf="isFreemiumExpired">
				Your freemium subscription has expired!. Please upgrade to a premium plan to continue minting the money.
			</div>
			<div class="flex flex-col md:flex-row items-center gap-6 md:gap-4 py-8">
				<app-widget-wizard-profile class="flex-1" [user$]="user$" [userTierProgress$]="userTierProgress$"
					[initials$]="initials$" [tradingVolume$]="tradingVolume$" [tradingPourcent$]="tradingPourcent$">
				</app-widget-wizard-profile>
				<!--Current (credit)-->
				<div class="flex-1 h-full px-4">
					<div class="flex items-center justify-center md:justify-start gap-x-2">
						<img src="/assets/img/illustrations/credit-card.png" class="object-contain w-14"
							alt="Illustration" />
						<div>
							<h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
								Current plan
							</h4>
							<h3 class="font-sans font-medium text-2xl text-secondary-500 capitalize">
								{{(user$ | async)?.plan.name}}
							</h3>
						</div>
					</div>

					<p class="font-sans text-2xl text-secondary-500 py-3 text-center md:text-left">
						<span>{{ currentPlan.price | currency:'EUR':'symbol':'1.2-2'}}</span>
						<span class="text-sm text-muted-400"> / month</span>
					</p>

					<div class="flex justify-center items-end h-16" *ngIf="!subscriptionLoaded">
						<span class="circle-loader"></span>
					</div>

					<div *ngIf="!showTopUpForm && currentSubscription !== null && currentAddons?.length > 0">
						<h4 class="text-[0.65rem] font-semibold text-white uppercase mb-2">
							Addons
						</h4>
						<p *ngFor="let addon of currentAddons" class="font-sans text-sm text-muted-400">
							{{addon.name}}
						</p>
					</div>

					<h4 class="text-[0.65rem] font-semibold text-white uppercase mt-4 mb-2"
						*ngIf="!showTopUpForm && currentAddons?.length > 0">
						Payment
					</h4>

					<div class="space-y-3 font-sans pr-4" *ngIf="!showTopUpForm && currentSubscription !== null">
						<!--Item-->
						<div class="flex items-center justify-between">
							<span class="text-sm text-muted-400 pr-20">Method</span>
							<span class="text-muted-600 dark:text-muted-100 text-sm">{{currentSubscription.isCreditCard
								? 'Credit Card' : '$BCUBE'}}</span>
						</div>
						<!--Item-->
						<div class="flex items-center justify-between" *ngIf="currentSubscription.isCreditCard">
							<span class="text-sm text-muted-400 pr-20">Next billing</span>
							<span
								class="text-muted-600 dark:text-muted-100 text-sm">{{currentSubscription?.nextBillingDate
								| ldate}}
							</span>
						</div>
						<div class="flex items-center justify-between" *ngIf="! currentSubscription.isCreditCard">
							<span class="text-sm text-muted-400 pr-20">Expiration</span>
							<span
								class="text-muted-600 dark:text-muted-100 text-sm">{{currentSubscription?.currentTermEnd
								| ldate}}
							</span>
						</div>
						<div class="flex items-center justify-between"
							*ngIf="! showTopUpForm && !currentSubscription.isCreditCard">
							<span class="text-sm text-muted-400 pr-20"></span>
							<span class="text-muted-600 dark:text-muted-100 text-sm">
								<button (click)="showTopUpForm = true"
									class="w-20 gap-x-2 px-2 rounded-md h-8 bg-secondary-500 duration-300
									hover:bg-secondary-500/90 hover:bg-muted-100 hover:shadow-secondary-500/30 hover:shadow-md text-white transition-all">
									Top Up
								</button>
							</span>
						</div>
					</div>
					<app-bcube-payment-form [(months)]="months" [amount]="bcubePaymentDetails?.priceDiscount"
						*ngIf="showTopUpForm">
					</app-bcube-payment-form>
					<div class="text-right pt-4" *ngIf="showTopUpForm">
						<a class="text-xs underline pr-3 align-sub cursor-pointer"
							(click)="showTopUpForm = false">Cancel</a>
						<button (click)="topUp()" [disabled]="isPayButtonDisabled()" class="w-20 gap-x-2 px-5 rounded-md h-8 bg-secondary-500 duration-300 inline-flex items-center justify-center
							hover:bg-secondary-500/90 hover:bg-muted-100 hover:shadow-secondary-500/30 hover:shadow-md text-white transition-all
							disabled:cursor-not-allowed disabled:opacity-40" [ngClass]="[isLoadingTopUp ? 'button-loading' : '']">
							<span class="font-sans text-sm">Pay</span>
						</button>
					</div>
				</div>

				<!--Next -->
				<app-widget-wizard-next-plan [nextPlan]="nextPlan$ | async" *ngIf="nextPlanAvailable$ | async"
					[planDifference]="planDifference$ | async"></app-widget-wizard-next-plan>

				<!-- No next -->
				<div *ngIf="!(nextPlanAvailable$ | async)"
					class="flex-1 bg-white border-2 border-brand p-10 rounded-lg">
					<div class="flex items-center gap-x-2 mb-4">
						<img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14"
							alt="Illustration" />
						<div>
							<h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
								Scaling
							</h4>
							<h3 class="font-sans font-medium text-2xl text-brand capitalize">
								Contact our team
							</h3>
						</div>
					</div>
					<div class="font-sans">
						<p class="text-sm text-muted-400 py-3">
							You reach the maximum plan at b-cube.ai. Explore more investment opportunities by
							contacting <a class="underline" href="mailto:contact@b-cube.ai">our team</a>.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
